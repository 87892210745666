import React, {useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from "react-router-dom";

import './projects_v1.css';


export function Project(prop) {
    return(
        <Row>
            <Col md='5' className='info m-auto d-none d-md-block'>
                <div>
                    <h1 className='target'>{prop.target}</h1>
                    <h2 className={prop.namecolor}>
                        {prop.name}
                    </h2>
                    <h2 className='gray'>
                        {prop.dev}
                    </h2>
                    <h2 className='gray'>
                        {prop.service}
                    </h2>
                    <br />
                </div>
            </Col>
            <Col md='auto' className='timeline d-none d-md-block'>
                <img className='timeline_dot' src='img/home/timeline_dot.png' />
                <div className={'theline ' + (prop.linepos || ' ')} />
            </Col>
            <Col xs='12' md='5' className='project_img m-auto py-2'>
                <Link to={prop.link || '/coming_soon'}>
                    <img className='img-fluid' src={prop.img} />
                </Link>
            </Col>
            <Col xs='12' sm='11' className='d-block d-md-none mx-auto'>
                <div>
                    <div className='d-flex justify-content-between'>
                        <h3 className='my-auto'>{prop.target}</h3>
                        <h3 className={'my-auto     ' + prop.namecolor}>
                            {prop.name}
                        </h3>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <h4 className='my-auto gray'>
                            {prop.dev}
                        </h4>
                        <h4 className='my-auto gray'>
                            {prop.service }
                        </h4>
                    </div>
                </div>
                <p />
            </Col>
        </Row>
    )
}

export default function Projects_v1(){
    //i18n
    const { t, i18n } = useTranslation('home/projects');

    return(
        <Container fluid className='projects mx-auto justify-content-center pt-5 pb-5' style={{backgroundImage: "url('/img/home/projects_bg.jpg')"}}>
            <br/>
            <Project 
                target={t("ilb.target")}
                name={t("ilb.name")}
                namecolor={t("ilb.namecolor")}
                dev={t("ilb.dev")}
                service={t("ilb.service")}
                img='/img/home/project_ilb.png'
                link='https://iloveburger.gmillion.io'
                linepos='start'
            />
            <Project 
                target={t("megam.target")}
                name={t("megam.name")}
                namecolor={t("megam.namecolor")}
                dev={t("megam.dev")}
                service={t("megam.service")}
                img='/img/home/project_megam.png'
                // link='https://iloveburger.gmillion.io'
                // linepos='end'
            />
            <Project 
                target={t("retohub.target")}
                name={t("retohub.name")}
                namecolor={t("retohub.namecolor")}
                dev={t("retohub.dev")}
                service={t("retohub.service")}
                img='/img/home/project_retohub.png'
                // link='https://iloveburger.gmillion.io'
                // linepos='end'
            />
            <Project 
                target={t("minegame.target")}
                name={t("minegame.name")}
                namecolor={t("minegame.namecolor")}
                dev={t("minegame.dev")}
                service={t("minegame.service")}
                img='/img/home/project_minegame.png'
                // link='https://iloveburger.gmillion.io'
                // linepos='end'
            />

            <Project 
                target={t("fog.target")}
                name={t("fog.name")}
                namecolor={t("fog.namecolor")}
                dev={t("fog.dev")}
                service={t("fog.service")}
                img='/img/home/project_fog.png'
                // link='https://iloveburger.gmillion.io'
                linepos='end'
            />
            <br/>
        </Container>
    )
}