export const maskEmail = (email) => {
  if(email == null) return '';

  const parts = email.split('@');
  const name = parts[0];
  const domain = parts[1];

  // Get the first and last characters of the name
  const maskedName = name.length >= 2 ? `${name[0]}xxx${name[name.length - 1]}` : name;

  // Combine the masked name and domain with "@" symbol
  const maskedEmail = `${maskedName}@${domain}`;

  return maskedEmail;
};