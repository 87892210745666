import React, {useEffect } from 'react';
import { useState, useRef } from "react"
import { useTranslation } from 'react-i18next';


import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Icon from '@mui/material/Icon';

import GMFormPage from "../GMElements/GMformPage"
import * as Validators from '../utils/validators'
import * as gmAPI from "../utils/GMillionAPI";
import { useNavigate } from "react-router-dom";


import "./change_password.css"

export default function Change_password(){
    //i18n
    const { t, i18n } = useTranslation('change_password');

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        // email: 'tanit+0017@gmillion.io',
        oldPassword: '12345678@aA',
        newPassword: '12345678@aAb',
        confirmPassword: '12345678@aAb',
    });

    const [oldPasswordError, setOldPasswordError] = useState(null);
    const [newPasswordError, setNewPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [returnError, setReturnError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleChange = (e) => {
        const { name, value, type, files, checked } = e.target;
        const newValue = type === 'file' ? files[0] : (type === 'checkbox' ? checked : value);

        switch(name){
            case 'oldPassword':
            case 'newPassword':
            case 'confirmPassword':
                if(newValue.length > 32) return;
        }

        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: newValue,
        }));
    };

    const validation = (e) => {
        setOldPasswordError(null);
        setNewPasswordError(null);
        setConfirmPasswordError(null);

        //validate password
        if(!Validators.validatePassword(formData.oldPassword)){
            setOldPasswordError(t('error_oldPassword'))
            return false;
        }

        //validate password
        if(!Validators.validatePassword(formData.newPassword) || (formData.newPassword == formData.oldPassword)){
            setNewPasswordError(t('error_newPassword'))
            return false;
        }

        //validate confirm password
        if(!(formData.newPassword == formData.confirmPassword)){
            setConfirmPasswordError(t('error_confirmPassword'));
            return false;
        }

        return true;
    }

    const doChangePassword = async () => {
        setReturnError(null);
        try{
            const response = await gmAPI.post(
                '/change_password', 
                JSON.stringify({ 
                    oldpass: formData.oldPassword,
                    newpass: formData.newPassword,
                })
            )

            //on success, to home
            navigate(`/`);
        }catch(err){
            console.log(err);

            if (!err?.response) {
                setReturnError(t('err504'));
                // alert("No Server Response");
            }else if (err.response?.status == null){
                setReturnError(t('err500'));
            }else{
                setReturnError( t(`err${err.response?.status}`) );
            }
        }finally{
            setLoading(false);
        }

    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if(!validation()){
            setLoading(false)
            return;
        }

        doChangePassword();
    }

    return(
        <GMFormPage wrapperClass='change_password' isloading={loading}>
             <FormControl onSubmit={handleSubmit}>
                <h3><b>{t('title')}</b></h3>
                <div className="form-div">
                    <label>{t('label_oldPassword')}</label>
                    <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        className="form-field"
                        placeholder = {t('placeholder_oldPassword')} 
                        name='oldPassword'
                        required
                        
                        value={formData.oldPassword}
                        onChange={handleChange}
                        helperText={oldPasswordError}
                        error={oldPasswordError}
                        variant="outlined"
                        
                        size={'small'}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <Icon onClick={togglePasswordVisibility}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon/>}
                                </Icon>
                            </InputAdornment>
                            ),
                        }}
                    />
                    {/* <InputAdornment>
                        <VisibilityIcon />
                    </InputAdornment> */}
                </div>
                <br/>

                <div className="form-div">
                    <label>{t('label_newPassword')}</label>
                    <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        className="form-field"
                        placeholder = {t('placeholder_newPassword')} 
                        name='newPassword'
                        required
                        
                        value={formData.newPassword}
                        onChange={handleChange}
                        helperText={newPasswordError}
                        error={newPasswordError}
                        variant="outlined"
                        
                        size={'small'}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <Icon onClick={togglePasswordVisibility}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon/>}
                                </Icon>
                            </InputAdornment>
                            ),
                        }}
                    />
                    {/* <InputAdornment>
                        <VisibilityIcon />
                    </InputAdornment> */}
                </div>
                <br/>

                <div className="form-div">
                    <label>{t('label_confirmPassword')}</label>
                    <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        className="form-field"
                        placeholder = {t('placeholder_confirmPassword')}
                        name='confirmPassword'
                        required

                        helperText={confirmPasswordError}
                        error={confirmPasswordError}
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        size={'small'}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <Icon onClick={togglePasswordVisibility}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon/>}
                                </Icon>
                            </InputAdornment>
                            ),
                        }}
                    />
                    </div>
                <br/>

                <div className='d-flex'>
                    
                    <p className='ms-0 form-error'>
                        {returnError}
                    </p>
                </div>
                <br/>

                <div className="form-div d-grid">
                    <button type="conFirm" className="blue_box mx-auto w-100" 
                        onClick={handleSubmit}>
                        {t('btn_confirm')}
                    </button>
                </div>
            </FormControl>
        </GMFormPage>
    )
}