export const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export function validatePassword(password) {
    const specialCharacters = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    const numeric = /[0-9]/;
    const uppercase = /[A-Z]/;
    const lowercase = /[a-z]/;
  
    return (
      password.length >= 8 &&
      specialCharacters.test(password) &&
      numeric.test(password) &&
      uppercase.test(password) &&
      lowercase.test(password)
    );
}