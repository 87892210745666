import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/esm/Image';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./coming_soon.css";


export default function Coming_soon(){
    //i18n
    // const { t, i18n } = useTranslation('coming_soon/coming_soon');
    // const [isth, setlang] = useState(true)

    // const changeLanguage = bt => {
    //     setlang(!isth);
    //     if(isth){
    //         i18n.changeLanguage('en');
    //     }else{
    //         i18n.changeLanguage('th');
            
    //     }
    // }

    return(
        <div className='coming_soon'>
            <div className='bg'>
                <img className="mx-auto" src="./img/coming_soon/W_GM_COMINGSOON2.jpg"></img>
            </div>
            <div className='context p-5'>
                <br/>
                <img className='glogo img-fluid' src='/img/commons/g_logo_372.png' />
                <h1>COMING SOON</h1>
                <h4>Thanks for support us</h4>
                <Link to='/home'>
                    <button className={'blue_box '} >HOMEPAGE</button>
                </Link>
                <br/>
            </div>
            
        </div>
    )
}