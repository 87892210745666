import React, {useEffect } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import "./reset_password.css"
import Send_mail from './send_mail';
import Change_password from './change_password';


export default function Reset_Password(){
    //i18n
    const { t, i18n } = useTranslation('reset_password');

    const location = useLocation();
    
    const token = new URLSearchParams(location.search).get("token")
    return(
        <div className='reset_password'>
            {token && <Change_password />}
            {!token && <Send_mail />}
        </div>
    )        
}