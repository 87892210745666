import React, {useEffect } from 'react';
import { useState, useRef } from "react"

import {RotatingLines} from 'react-loader-spinner';

import "./GMformPage.css"

export default function GMFormPage(props){
    return(
        <div className={`GMformPage ${props.wrapperClass}`}>
            <div className='context mx-auto py-5' >
                <div className='bg d-none d-sm-block ani_fade_in' style={{ 
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/img/commons/bg.jpg"})`}}>
                </div>
                <div className='inner_box mx-auto p-5'>
                    {(props.isloading && <div className='loader d-flex justify-content-center rounded'>
                        <RotatingLines visible={true} ariaLabel="loading" strokeColor="grey"
                                        strokeWidth="5"
                                        animationDuration="0.75" width="64" wrapperClass="progress m-auto" 
                                        className='progress m-auto' />
                    </div>)}
                    
                    {props.children}
                </div>
            </div>
        </div>
    )
}