import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./footer.css"

export default function Footer(){
    //i18n
    const { t, i18n } = useTranslation('footer');
    const [isth, setlang] = useState(true)

    return(
        <div className='footer'>
            <br/><br/>
            <div className='maxw-1920 footer-inner mx-auto justify-content-center '>
                {/* <div className='mx-auto p-3' id='payments'>
                    <div id='payment_content' className='mx-auto d-flex justify-content-center'>
                        <img className='img-fluid' src='./img/footer/payments.png'></img>
                    </div>
                </div> */}
                <div className='mx-auto px-2 d-flex flex-row justify-content-between' id='footer_body'>
                    <div className='address d-flex flex-column pt-0 mx-auto'>
                        <span className={'d-block d-flex m-0 ' + i18n.language}><p>
                            <span className={'h5 bold blueee ' + i18n.language}>{t('ADDRESS.G_MILLION')}</span>
                            <span className={'h5 bold black26 ' + i18n.language}>{t('ADDRESS.THAILAND')}</span></p>
                        </span>
                        
                        <span className={'content h5 d-flex flex-column m-0 ' + i18n.language}>
                            {/* <p className=''>{t('ADDRESS.ADDRESS')}</p> */}
                            {/* <p className='d-none d-lg-block'>{t('ADDRESS.EMAIL_TEL')}</p> */}
                            <p>{t('ADDRESS.ADDRESS1')}</p>
                            <p>{t('ADDRESS.ADDRESS2')}</p>
                            <p>{t('ADDRESS.ADDRESS3')}</p>
                            <p className='d-none d-lg-block'>{t('ADDRESS.EMAIL')}</p>
                            <p className='d-none d-lg-block'>{t('ADDRESS.TEL')}</p>

                        </span>
                        <div className='d-block d-md-none'>
                            <p/>
                            <span className={'d-block ' + i18n.language}><p><span className='h5 blueee '>{t('ADDRESS.GM')}</span><span className={'h5 black26' + i18n.language}>{t('ADDRESS.CONTACT')}</span></p></span>
                            <span className={'content h5 d-flex flex-column align-items-center ' + i18n.language}>
                            {/* <p className='content'>{t('ADDRESS.EMAIL_TEL')}</p> */}
                                <p className=''>{t('ADDRESS.EMAIL')}</p>
                                <p className=''>{t('ADDRESS.TEL')}</p>
                            </span>
                        </div>
                    </div>
                    <div id='about' className='d-none d-lg-block d-flex flex-column align-items-start p-2 mx-auto'>
                        <span className={'h5 d-flex bold black26 m-0 ' + i18n.language}><p>{t('ABOUT.TITLE')}</p></span>
                        <span className={'content h5 d-flex flex-column align-items-start ' + i18n.language}>
                        <p>{t('ABOUT.ABOUT_US')}</p>
                        <p>{t('ABOUT.CAREERS')}</p>
                        <p>{t('ABOUT.CONTACTS')}</p>
                        <p>{t('ABOUT.G_MILLION_BLOG')}</p>
                        </span>
                    </div>
                    <div className='d-none d-lg-block d-flex flex-column align-items-start p-2 mx-auto'>
                        <span className={'h5 d-flex bold black26 m-0 ' + i18n.language}><p>{t('PRODUCTS.TITLE')}</p></span>
                        <span className={'content h5 d-flex flex-column align-items-start ' + i18n.language}>
                        <p>{t('PRODUCTS.ACCOUNT')}</p>
                        <p>{t('PRODUCTS.EDIT_TOOL')}</p>
                        <p>{t('PRODUCTS.EXCHANGE')}</p>
                        <p>{t('PRODUCTS.MARKETPLACE')}</p>
                        </span>
                    </div>
                    <div className='d-none d-lg-block d-flex flex-column align-items-start p-2 mx-auto'>
                        <span className={'h5 d-flex bold black26 m-0 ' + i18n.language}><p>{t('SUPPORT.TITLE')}</p></span>
                        <span className={'content h5 d-flex flex-column align-items-start ' + i18n.language}>
                        <p>{t('SUPPORT.AFF_PROGRAM')}</p>
                        <p>{t('SUPPORT.TICKET')}</p>
                        <p>{t('SUPPORT.FEEDBACK')}</p>
                        <p>{t('SUPPORT.VERFIFICATION')}</p>
                        </span>
                    </div>
                    <div className='d-none d-lg-block d-flex flex-column align-items-start p-2 mx-auto'>
                        <span className={'h5 d-flex bold black26 m-0 ' + i18n.language}><p>{t('LEGAL.TITLE')}</p></span>
                        <span className={'content h5 d-flex flex-column align-items-start ' + i18n.language}>
                        <p>{t('LEGAL.TERM_CON')}</p>
                        <p>{t('LEGAL.PRIVACY')}</p>
                        <p>{t('LEGAL.COPYRIGHT')}</p>
                        <p>{t('LEGAL.COOKIE')}</p>
                        </span>
                    </div>
                    <div className='d-none d-md-block d-lg-none d-flex flex-column p-2 mx-auto'>
                            <span className={'d-flex flex-column align-items-end ' + i18n.language}><p><span className='h5 bold blueee '>{t('ADDRESS.GM')}</span><span className={'h5 bold black26' + i18n.language}>{t('ADDRESS.CONTACT')}</span></p></span>
                            <span className={'email2 content d-flex flex-column align-items-start ms-0 ' + i18n.language}>
                                {/* {t('ADDRESS.EMAIL_TEL')} */}
                                <p className=''>{t('ADDRESS.EMAIL')}</p>
                                <p className=''>{t('ADDRESS.TEL')}</p>
                            </span>
                    </div>
                </div>
                
                <div className='d-flex justify-content-center align-items-end pt-0 px-2 mt-0'>
                    <div id='social' className='d-flex justify-content-center'>
                    <Link to="/coming_soon"><img className='isocial img-fluid' src='./img/footer/i64x64_insta.png' /></Link>
                    <Link to="/coming_soon"><img className='isocial img-fluid' src='./img/footer/i64x64_tweeter.png' /></Link>
                    <Link to="/coming_soon"><img className='isocial img-fluid' src='./img/footer/i64x64_linkin.png' /></Link>
                    <Link to="/coming_soon"><img className='isocial img-fluid' src='./img/footer/i64x64_face.png' /></Link>
                    <Link to="/coming_soon"><img className='isocial img-fluid' src='./img/footer/i64x64_youtube.png' /></Link>
                    <Link to="/coming_soon"><img className='isocial img-fluid' src='./img/footer/i64x64_discord.png' /></Link>
                    <Link to="/coming_soon"><img className='isocial img-fluid' src='./img/footer/i64x64_tele.png' /></Link>
                    </div>
                </div>


                {/* <hr /> */}
                <div className='mx-auto p-0 m-0 d-flex justify-content-center' style={{width: "100%"}}>
                    <div className='divider p-0' id=''><hr /></div>
                </div>
            </div>
            <div className={'mx-auto p-1 copyright ' + i18n.language} id='copy'>
                <p>© 2023 G MILLION Co., Ltd All Right Reserved</p>
                
            </div>
        </div>
    )
}