import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/esm/Image';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { matchRoutes, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {maskEmail} from '../utils/maskers';
import { Dropdown } from 'react-bootstrap';

import {useCooker} from "../utils/cooker"

import "./header.css";

export default function Header(){
    const location = useLocation();
    const navigate = useNavigate();

    //i18n
    const { t, i18n } = useTranslation('header');
    // const [isth, setlang] = useState(true)

    const cooker = useCooker();
    const [email, setEmail] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const changeLanguage = bt => {
        // setlang(!isth);
        if(i18n.language == 'th'){
            i18n.changeLanguage('en');
        }else{
            i18n.changeLanguage('th');
            
        }
    }

    //navbar scroll when active state
    const [navbarAway, setNavbar] = useState('')

    //navbar scroll changing function
    const checkNavbarAway = () => {
        // console.log(window.scrollY)
        if (window.scrollY >= 128) {
            setNavbar('navaway')
        } else {
            setNavbar('')
        }
    }

    const handleLogout = () => {
        cooker.removeAccessToken();
        cooker.removeRefreshToken();
        cooker.removeEmail();
        navigate(location.pathname);    //force re-rendering since all useEffect take place before value change
    }

    useEffect(() => {
        checkNavbarAway()
        // adding the event when scroll change background
        window.addEventListener("scroll", checkNavbarAway)
    })

    useEffect(() => {
        setIsLoggedIn(cooker.email() != null);
        setEmail(cooker.email());
    })

    const tag_menu = () => {
        switch(location.pathname){
            case '/':
            case '/home':
                return(
                    <div className='d-flex'>
                        <Nav.Link href="#home_p2"><span className={'p-2 me-3 h5 bold gray80 ani_fade_in ' + i18n.language}>{t('WHO_WE_ARE')}</span></Nav.Link>
                        <Nav.Link href="#home_projects"><span className={'p-2 me-3 h5 bold gray80 ani_fade_in ' + i18n.language}>{t('OUR_PRODUCT')}</span></Nav.Link>
                        <Nav.Link href="#home_p4"><span className={'p-2 me-3 h5 bold gray80 ani_fade_in ' + i18n.language}>{t('MILESTONE')}</span></Nav.Link>
                        <Nav.Link href="#home_end"><span className={'p-2 h5 bold gray80 ani_fade_in ' + i18n.language}>{t('CONTACT')}</span></Nav.Link>
                    </div>
                )

        }
        return (<div></div>);
    }

    const LoginPanel = (justify) => {
        return(
            <div>
                {isLoggedIn && <div className='me-0 d-flex justify-content-end'>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            <span className='h5 gray80'>{maskEmail(email)}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {/* <Dropdown.Item href="#action1">Action 1</Dropdown.Item> */}
                            <Dropdown.Item tabIndex={0} href="/change_password">Change Password</Dropdown.Item>
                            <Dropdown.Item className='gray' tabIndex={1} onClick={handleLogout}>Log out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>}
                {!isLoggedIn && <div className='mx-auto d-flex justify-content-end '>
                    <Link to="/register">
                        <button className={'tran_box me-2 ' + i18n.language} id='p1_b1'>{t('REGISTER')}</button>
                    </Link>
                    <Link to="/login">
                        <button className={'blue_box ' + i18n.language} id='p1_b1'>{t('LOGIN')}</button>
                    </Link>
                </div>}
            </div>
        )
    }
    

    return(
        <Navbar className={'header mx-auto m-0 px-0 ' + navbarAway} expand="md" sticky='top' variant="light">
            <div className='d-none d-lg-block w-100 '>
                <div className='header-lg d-flex flex-row justify-content-between mx-auto p-0'>
                    <Navbar.Brand id="nav-brand" className="ms-5">
                        <Link to="/home">
                            <Image id="img-logo" className={"logo " + navbarAway} src='./img/header/gmillion_logo.png' alt="G Million" />
                        </Link>
                    </Navbar.Brand>
                    <div id="div-links" className='m-auto d-flex justify-content-evenly'>
                        {tag_menu()}
                    </div>
                    <Nav id="nav-lang" className="me-1 d-flex flex-row justify-content-end align-items-center">
                        <div className='mx-auto '>
                            <Image id='img_lang' fluid className='lang me-2 link-icon ani_fade_in '
                                src={i18n.language=='th' ? '/img/header/lang_th.png' : '/img/header/lang_en.png' } alt="lang" 
                                onClick={changeLanguage} />
                        </div>
                        <div className='me-5 d-flex justify-content-evenly '>
                            <LoginPanel />
                        </div>
                    </Nav>
                    
                </div>
            </div>
            <div fluid className='container-fluid d-block d-lg-none mx-auto justify-content-between' id='header-sm'>
                <div id="div-header-sm" className='d-flex justify-content-between'>
                    <Navbar.Brand id="nav-brand" href="/home" className="p-0">
                        <Image id="img-logo" className={"logo " + navbarAway} src='./img/header/gmillion_logo.png' alt="G Million" />
                    </Navbar.Brand>
                    {/* <div id="div-links" fluid className='m-auto d-flex justify-content-evenly'>
                        <Nav.Link href="#weare"><span className={'p-2 h5 gray80 ' + i18n.language}>{t('WHO_WE_ARE')}</span></Nav.Link>
                        <Nav.Link href="#milestone"><span className={'p-2 h5 gray80 ' + i18n.language}>{t('MILESTONE')}</span></Nav.Link>
                        <Nav.Link href="#products"><span className={'p-2 h5 gray80 ' + i18n.language}>{t('OUR_PRODUCT')}</span></Nav.Link>
                    </div> */}
                    <Nav id="nav-lang" className="my-auto d-flex flex-row align-items-end ">
                        <div className='mx-auto my-auto '>
                            <Image id='img_lang' fluid className='lang me-1 link-icon ani_fade_in '
                                src={i18n.language=='th' ? '/img/header/lang_th.png' : '/img/header/lang_en.png' } alt="lang" 
                                onClick={changeLanguage} />
                        </div>
                        <div className='mx-auto d-flex justify-content-end d-none d-sm-block'>
                            <LoginPanel />
                        </div>
                        {/* TODO <sm size menu page button */}
                    </Nav>
                    
                </div>
            </div>
        </Navbar>
    )
    
}